import React, { useState, useEffect, useRef } from 'react';
import Icons from '../../../Common/IconsComponent.jsx';
import { usePopup } from '../../../Popups/PopupContext.js';
import {
	useCheckTaskMutation,
	usePassPartnersMutation,
} from '../../../../services/phpService';
import { useTonConnectUI } from '@tonconnect/ui-react';
import useSaveAction from '../../../../helpers/analyticsHelper';
import { getTelegramShareLink } from '../../../../helpers/referralLinkHelper';
import { animateDoneTask } from '../../../../helpers/tasksAnimationHelper';

const TaskListItem = ({ task }) => {
	const { user, setUser, genHash, addNotification, isConnectWalletTaskDone } = usePopup();
	const [isClicked, setIsClicked] = useState(false);
	const [isCheckStage, setIsCheckStage] = useState(false);
	const [remainingTime, setRemainingTime] = useState(10);
	const [timerStarted, setTimerStarted] = useState(false);
	const [timerFinished, setTimerFinished] = useState(false);
	const [taskStatusDone, setTaskStatusDone] = useState(false);
	const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [checkTgTasks] = useCheckTaskMutation();
	const [checkTasks] = usePassPartnersMutation();
	const [tonConnectUI] = useTonConnectUI();
	const { saveAction } = useSaveAction();
	const notifiedTasksRef = useRef(new Set());
	const taskEl = useRef(null);

	useEffect(() => {
		let interval;
		if (timerStarted && !timerFinished) {
			interval = setInterval(() => {
				setRemainingTime((prev) => {
					if (prev > 1) {
						return prev - 1;
					} else {
						clearInterval(interval);
						setTimerFinished(true);
						return 0;
					}
				});
			}, 1000);
		}
		return () => clearInterval(interval);
	}, [timerStarted, timerFinished]);

	useEffect(() => {
		if (isConnectWalletTaskDone && task.code === 'is_wallet_connected') {
			setTaskStatusDone(true);
			animateDoneTask(taskEl);
		}
	}, [isConnectWalletTaskDone]);

	const openLink = (link) => {
		saveAction(user?.id_telegram, 'click_main_task_item', user?.parent_id);
		let code = task.code;

		if (code === 'is_wallet_connected') {
			if (task.status === 1) {
				if (!notifiedTasksRef.current.has(task.id)) {
					addNotification('success', 'Great job! The task is done!');
					notifiedTasksRef.current.add(task.id);
				}
				return;
			}

			if (!tonConnectUI.connected) {
				tonConnectUI.openModal();
			} else {
				addNotification(
					'success',
					'Wallet is already connected! Please, disconnect wallet from your profile first and then try again.'
				);
			}
			return;
		}

		if (code === 'referrals_link') {
			const share_link = getTelegramShareLink(user?.id_telegram);
			window.open(share_link, '_blank');
			return;
		}

		setTimerStarted(true);
		setIsCheckStage(true);
		setShowConfirmationMessage(true);
		setIsClicked(true);
		window.open(link, '_blank');
	};

	const startTimer = (event) => {
		event.stopPropagation();
		setTimerStarted(true);
	};

	const checkTask = async (event) => {
		event.stopPropagation();

		// Prevent multiple clicks or if the button is disabled
		if (isButtonDisabled || !timerFinished) {
			if (!timerFinished) {
				addNotification('error', 'Task is not completed. Please wait for the timer.');
			}
			return;
		}

		// Disable the button immediately
		setIsButtonDisabled(true);

		let code = task.code;

		// Check Telegram task logic
		if (code === 'tg_channel' || code === 'tg_chat') {
			try {
				const res = await checkTgTasks({
					token: await genHash(),
					wallet_address: user?.wallet_address,
					id_telegram: user?.id_telegram,
					code: code,
				}).unwrap();

				if (res.success) {
					await handleCheckTaskSuccess();
				} else {
					addNotification('error', 'Task check failed. Please try again.');
				}
			} catch (e) {
				console.error(e);
				addNotification('error', 'Oops... Please try again later.');
			}
			return;
		}

		// Regular task check
		try {
			await handleCheckTaskSuccess();
		} catch (e) {
			console.error(e);
			addNotification('error', e?.data?.message || 'Oops... Please try again later.');
		}
	};

	const handleCheckTaskSuccess = async () => {
		const res = await checkTasks({
			token: await genHash(),
			id_telegram: user?.id_telegram,
			partners_quest_id: task?.id,
		}).unwrap();

		const taskExistsInAndDone = res.partners_quests?.some(
			(partnersTask) =>
				partnersTask.partners_quest_id === task.id && partnersTask.status === 1
		);

		if (taskExistsInAndDone) {
			setIsClicked(false);
			setTaskStatusDone(true);
			animateDoneTask(taskEl);
			setShowConfirmationMessage(false);

			const updatedUser = {
				...user,
				partners_quests: res.partners_quests,
				wallet_balance: res.wallet_balance,
			};
			setUser(updatedUser);
			addNotification('success', 'Great job! The task is done!');
		} else {
			addNotification('error', 'Task check failed. Please try again.');
		}
	};

	useEffect(() => {
		if (task.status === 1) setTaskStatusDone(true);
	}, [task]);

	return (
		<a href='#' className='tasks__link' onClick={() => openLink(task?.link)} ref={taskEl}>
			<div
				className='tasks__name'
				style={{ height: !taskStatusDone && showConfirmationMessage ? '75px' : 'auto' }}
			>
				{task?.name}
				{!taskStatusDone && showConfirmationMessage && (
					<div style={{ fontFamily: 'Kumbh', color: '#fff500', marginTop: '5px' }}>
						Are you certain you completed this task?
					</div>
				)}
			</div>

			{taskStatusDone ? (
				<div className='tasks__value tasks__value_done'>done</div>
			) : isClicked ? (
				<div className='tasks__value tasks__value_check'>
					{isCheckStage ? (
						timerStarted ? (
							timerFinished ? (
								<button onClick={checkTask} disabled={isButtonDisabled}>
									Claim
								</button>
							) : (
								<span onClick={(e) => e.stopPropagation()}>
									{remainingTime}&nbsp;<span style={{ fontSize: '14px' }}>s</span>
								</span>
							)
						) : (
							<button onClick={startTimer}>Check</button>
						)
					) : null}
				</div>
			) : (
				<div className='tasks__value'>
					+{task?.bonus} <Icons.ShitIcon />
				</div>
			)}
		</a>
	);
};

export default TaskListItem;
