import React, { useState, useEffect, useCallback } from 'react';
import { usePopup } from '../../../../Popups/PopupContext.js';
import { useVoteMutation, usePassTaskMutation } from '../../../../../services/phpService';
import { useNavigate } from 'react-router-dom';
import Icons from '../../../../Common/IconsComponent';
import ProjectTasks from './ProjectTasks.jsx';
import useSaveAction from '../../../../../helpers/analyticsHelper';
import './Project.scss';
import { getTelegramShareLink } from '../../../../../helpers/referralLinkHelper';
import { MyButton, MyBalance } from '../../../../Common/styles';

export default function Project({ project }) {
	const { genHash, addNotification, user, setUser, showPopup } = usePopup();
	const [totalVotes, setTotalVotes] = useState(project?.value);
	const [totalVoters, setTotalVoters] = useState(project?.valueVoters);
	const [voteTime, setVoteTime] = useState(0);
	const [isVoteReady, setIsVoteReady] = useState(true);
	const [isVoteLoading, setVoteLoading] = useState(false);
	const [isVoteBlock, setIsVoteBlock] = useState(true);
	const [blockTime, setBlockTime] = useState(10);
	const [disabledButtons, setDisabledButtons] = useState({});
	const [vote] = useVoteMutation();
	const navigate = useNavigate();
	const [checkTasks] = usePassTaskMutation();
	const { saveAction } = useSaveAction();

	const TIMER_STORAGE_KEY = 'project_vote_timers';

	const navBack = () => {
		navigate(`/activity/`);
	};

	const handleImageClick = () => {
		if (project?.has_game === 1) {
			navigate(`/projects/${project.id}/game`);
		}
	};

	const handleButtonClick = (taskId, link, isTaskCompleted) => {
		if (!isTaskCompleted) {
			checkTask();
			// addNotification('success', 'Task completed successfully!');
		} else {
			addNotification('info', 'Task is already competed');
		}

		openLink(link);
		saveAction(user?.id_telegram, 'click_proj_task', user?.parent_id, project.id, taskId);

		setDisabledButtons((prev) => ({ ...prev, [taskId]: true }));

		setTimeout(() => {
			setDisabledButtons((prev) => ({ ...prev, [taskId]: false }));
		}, 5000);
	};

	useEffect(() => {
		if (!user) navigate(`/?r=projects/${project.id}`);

		const interval = setInterval(() => {
			setBlockTime((prev) => {
				if (prev <= 1) {
					clearInterval(interval);
					setIsVoteBlock(false);
					return 0;
				}
				return prev - 1;
			});
		}, 1000);
	}, [user]);

	const getStoredTimers = useCallback(() => {
		const storedTimers = localStorage.getItem(TIMER_STORAGE_KEY);
		return storedTimers ? JSON.parse(storedTimers) : {};
	}, []);

	const getRemainingTime = useCallback(() => {
		const storedTimers = getStoredTimers();
		const projectTimer = storedTimers[project.id];

		if (projectTimer) {
			const currentTime = Date.now();
			const endTime = projectTimer;
			const remainingTime = endTime - currentTime;
			if (remainingTime > 0) {
				return Math.floor(remainingTime / 1000);
			} else {
				delete storedTimers[project.id];
				localStorage.setItem(TIMER_STORAGE_KEY, JSON.stringify(storedTimers));
				return 0;
			}
		}
		return 0;
	}, [getStoredTimers, project.id]);

	const startTimer = useCallback(
		(voteTimer) => {
			const endTime = Date.now() + voteTimer * 1000;
			const storedTimers = getStoredTimers();
			storedTimers[project.id] = endTime;
			localStorage.setItem(TIMER_STORAGE_KEY, JSON.stringify(storedTimers));
			setVoteTime(voteTimer);
			setIsVoteReady(false);
		},
		[getStoredTimers, project.id]
	);

	useEffect(() => {
		const remainingTime = getRemainingTime();
		setVoteTime(remainingTime);

		if (remainingTime > 0) {
			setIsVoteReady(false);
			const interval = setInterval(() => {
				setVoteTime((prev) => {
					if (prev <= 1) {
						clearInterval(interval);
						const storedTimers = getStoredTimers();
						delete storedTimers[project.id];
						localStorage.setItem(TIMER_STORAGE_KEY, JSON.stringify(storedTimers));
						setIsVoteReady(true);
						return 0;
					}
					return prev - 1;
				});
			}, 1000);

			return () => clearInterval(interval);
		} else {
			setIsVoteReady(true);
		}
	}, [getRemainingTime, getStoredTimers, project.id]);

	useEffect(() => {
		if (voteTime > 0 && !isVoteReady) {
			const interval = setInterval(() => {
				setVoteTime((prev) => {
					if (prev <= 1) {
						clearInterval(interval);
						const storedTimers = getStoredTimers();
						delete storedTimers[project.id];
						localStorage.setItem(TIMER_STORAGE_KEY, JSON.stringify(storedTimers));
						setIsVoteReady(true);
						return 0;
					}
					return prev - 1;
				});
			}, 1000);

			return () => clearInterval(interval);
		}
	}, [voteTime, isVoteReady, getStoredTimers, project.id]);

	const theShitHandler = async (project) => {
		if (isVoteBlock) return;
		if (!isVoteReady) {
			addNotification(
				'error',
				`You can't vote right now. Please wait ${Math.floor(voteTime / 60)} minutes.`
			);
			return;
		}

		if (!user?.wallet_balance || user?.wallet_balance <= 0) {
			addNotification(
				'error',
				'Your $hit balance is empty. Please refill balance to vote.'
			);
			return;
		}

		setVoteLoading(true);
		try {
			const res = await vote({
				token: await genHash(),
				id_telegram: user?.id_telegram,
				project_id: project.id,
			}).unwrap();
			setTotalVotes(res.votes_total);
			addNotification('success', 'Your vote counted!');
			startTimer(res.vote_timer);
			setVoteLoading(false);
			if (res.success) {
				const updatedUser = {
					...user,
					wallet_balance: res.user_balance,
				};
				setUser(updatedUser);
			}
		} catch (e) {
			addNotification('error', 'Your vote was not counted. Please try again.');
			setVoteLoading(false);
		}
	};

	const handleMakeClick = (projectId) => {
		if (project?.tasks?.length > 0) {
			showPopup(<ProjectTasks projectId={projectId} />);
		} else {
			addNotification('info', 'There are no tasks available');
		}
	};

	const handleCopyToClipboard = () => {
		navigator.clipboard
			.writeText(project.contract)
			.then(() => {
				addNotification('info', 'Contract copied!');
			})
			.catch((err) => {
				addNotification('error', 'Failed to copy');
			});
	};

	const formatTime = (timeInSeconds) => {
		const minutes = Math.floor(timeInSeconds / 60);
		const seconds = timeInSeconds % 60;
		return `${minutes}:${String(seconds).padStart(2, '0')}`;
	};

	const secondsFormatTime = (timeInSeconds) => {
		const seconds = timeInSeconds % 60;
		return `${String(seconds)}`;
	};

	const inviteTG = () => {
		const shareUrl = getTelegramShareLink(user.id_telegram, project.id);
		window.open(shareUrl, '_blank');
	};

	const openLink = (link) => {
		window.open(link, '_blank');
	};

	const checkTask = async () => {
		try {
			const res = await checkTasks({
				token: await genHash(),
				id_telegram: user?.id_telegram,
				projects_task_id: project.tasks[0].taskId,
			}).unwrap();

			if (res) {
				const updatedUser = {
					...user,
					projects_tasks: res.projects_tasks,
					wallet_balance: res.wallet_balance,
				};
				setUser(updatedUser);
				addNotification('success', 'Task completed successfully!');
			} else {
				addNotification('error', 'Task check failed. Please try again.');
			}
		} catch (e) {
			addNotification('info', 'Task is already competed');
		}
	};

	return (
		<>
			<div className='projects__slide'>
				<div className='projects__card'>
					<MyButton
						variant='smallIcon'
						style={{ top: '20px', left: '20px' }}
						onClick={() => navBack()}
					>
						<Icons.Larrow />
					</MyButton>
					<MyButton
						variant='smallIcon'
						style={{ top: '20px', right: '20px' }}
						onClick={inviteTG}
					>
						<Icons.Invite />
					</MyButton>

					{project.has_game === 1 && (
						<div className='projects__eventVotes'>
							<div className='projects__eventVotes-left'>
								<span className='projects__eventVotes-text'>Users Voted</span>
								<span className='projects__eventVotes-number'>
									<Icons.User />
									{totalVoters}
								</span>
							</div>
							<div className='projects__eventVotes-right'>
								<span className='projects__eventVotes-text'>Total Votes</span>
								<span className='projects__eventVotes-number'>
									{totalVotes}
									<Icons.Shit />
								</span>
							</div>
						</div>
					)}
					{project.has_game !== 1 && (
						<MyBalance>
							<div>
								{totalVotes} 
								<Icons.Shit />
							</div>
						</MyBalance>
					)}
					{project.has_game !== 1 && (
						<div className='projects__rank'>rank: {project.rank}</div>
					)}
					<div className='projects__image' onClick={handleImageClick}>
						{project.has_game === 1 && <Icons.highlight />}
						<picture>
							<img src={project.imgSrc} alt='Image' />
						</picture>
					</div>
					<div className='projects__name'>{project.name}</div>
					{project.tokenName !== null ? (
						<div className='projects__extra'>{project.tokenName}</div>
					) : null}
					{project.contract !== null ? (
						<div
							className='projects__contract'
							onClick={handleCopyToClipboard}
							style={{ cursor: 'pointer' }}
						>
							contract:{' '}
							<span className='projects__contract_name'> {project.contract}</span>
							<Icons.Copy />
						</div>
					) : null}
					<div className='projects__buttons'>
						<MyButton
							onClick={() => theShitHandler(project)}
							variant={!isVoteReady ? 'inactive' : 'default'}
							className={`mainBtn`}
							disabled={!isVoteReady || isVoteLoading}
						>
							{isVoteBlock && isVoteReady ? (
								<>Vote in {secondsFormatTime(blockTime)}</>
							) : isVoteReady ? (
								<>the $hit!</>
							) : (
								<>cool down: {formatTime(voteTime)}</>
							)}
						</MyButton>
						{project?.tasksCount >= 2 ? (
							<MyButton
								onClick={() => handleMakeClick(project?.id)}
								variant='black'
								className='mainBtn'
							>
								tasks <span style={{ color: 'black' }}>{project?.tasksCount}</span>
							</MyButton>
						) : (
							<div>
								{project.tasks.length === 1 ? (
									project.tasks.map((task) => {
										const isTaskCompleted = user.projects_tasks?.some(
											(userTask) => userTask.projects_task_id === task.taskId
										);

										const isDisabled = disabledButtons[task.taskId];

										return (
											<MyButton
												key={task.taskId}
												onClick={() =>
													handleButtonClick(task.taskId, task.link, isTaskCompleted)
												}
												className={`mainBtn`}
												variant={isDisabled ? 'inactive' : 'black'}
												disabled={isDisabled}
											>
												{task.name}
											</MyButton>
										);
									})
								) : (
									<p>No tasks available for this project.</p>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
