import React from 'react';
import { usePopup } from '../../../../../Popups/PopupContext.js';
import Icons from '../../../../../Common/IconsComponent.jsx';
import EventTasksItem from './EventTasksItem.jsx';

const EventTasks = ({ tasks, eventId }) => {
	const { hidePopup } = usePopup();

	const closeModal = () => {
		hidePopup();
	};

	const getLiClassName = (index, status) => {
		let classname = '';
		if (index >= 5) {
			classname = status ? 'hidden' : 'waiting';
		}
		return 'tasks__item ' + classname;
	};

	return (
		<div className='main__tasks tasks'>
			<div className='modal__close' onClick={closeModal}>
				<Icons.Close />
			</div>
			<ul className='tasks__list'>
				{tasks.map((task, index) => (
					<li key={index} className={getLiClassName(index, task.status)}>
						<EventTasksItem task={task} eventId={eventId} />
					</li>
				))}
			</ul>
		</div>
	);
};

export default EventTasks;
