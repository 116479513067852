import React, { useEffect, useState } from 'react';
import NavigationWidget from '../NavigationWidget';
import Icons from '../../../Common/IconsComponent.jsx';
import { useNavigate } from 'react-router-dom';
import { usePopup } from '../../../Popups/PopupContext';
import { TonConnectButton } from '@tonconnect/ui-react';
import { useTonConnectUI } from '@tonconnect/ui-react';
import potatoImage from '../../../../assets/img/splash/potato_crown.png';
import {
	getTelegramShareLink,
	getReferralLink,
} from '../../../../helpers/referralLinkHelper';
import Preloader from '../Preloader/Preloader';
import { MyButton, MyBalance, MyAnnounce } from '../../../Common/styles';
import './ProfileScreen.scss';

const ProfileComponent = () => {
	const { user, addNotification, setUserDataPollingDisabled } = usePopup();
	const [generatedUrl, setGeneratedUrl] = useState('');
	const [ballance, setBallance] = useState(0);
	const [username, setUsername] = useState('');
	const [referrals, setReferrals] = useState(0);
	const [showProfile, setShowProfile] = useState(true);
	const tg = window.Telegram.WebApp;
	const initData = tg.initDataUnsafe;
	const [photoUrl, setPhotoUrl] = useState('');
	const [loading, setLoading] = useState(true);
	const [loadingProfile, setLoadingProfile] = useState(false);

	const [tonConnectUI] = useTonConnectUI();

	useEffect(() => {
		if (initData) {
			if (initData.user?.photo_url) {
				setPhotoUrl(initData.user.photo_url);
			}
		} else {
			console.log('Telegram WebApp or initDataUnsafe is not available');
		}
	}, [initData]);

	const navigate = useNavigate();
	useEffect(() => {
		if (!user) navigate('/?r=profile');
		else setUserDataPollingDisabled(false);
	}, []);

	useEffect(() => {
		if (initData) {
			if (initData.user?.photo_url) {
				setPhotoUrl(initData.user.photo_url);
			}
		}
	}, [initData]);

	useEffect(() => {
		if (user && user.id_telegram) {
			const referralURL = getReferralLink(user.id_telegram);
			setGeneratedUrl(referralURL);
			localStorage.setItem('generatedUrl', referralURL);
			setBallance(user.wallet_balance);
			setUsername(user.username);
			setReferrals(user.referrals_count);
		}
	}, [user]);

	// frens
	useEffect(() => {
		const storedUrl = localStorage.getItem('generatedUrl');
		if (storedUrl) {
			setGeneratedUrl(storedUrl);
		}
	}, []);

	const inviteTG = () => {
		const shareUrl = getTelegramShareLink(user.id_telegram);
		window.open(shareUrl, '_blank');
	};

	const copyToClipboard = () => {
		navigator.clipboard
			.writeText(generatedUrl)
			.then(() => {
				addNotification('success', 'Referral link copied!');
			})
			.catch((err) => {
				addNotification('error', 'Failed to copy');
			});
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setLoading(false);
		}, 500);

		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		if (loadingProfile) {
			const timer = setTimeout(() => {
				setLoadingProfile(false);
			}, 500); // Match the duration of the preloader

			return () => clearTimeout(timer);
		}
	}, [loadingProfile]);

	const handleProfileToggle = () => {
		setLoadingProfile(true); // Show preloader
		setTimeout(() => {
			setShowProfile((prev) => !prev);
		}, 500); // Match the duration of the preloader
	};

	const [isWalletButtonRef, setIsWalletButtonRef] = useState(false);

	const showConnectModal = () => {
		setIsWalletButtonRef(true);
		setTimeout(() => setIsWalletButtonRef(false), 200);
		if (!tonConnectUI.connected) tonConnectUI.openModal();
	};

	return (
		<>
			{loading || loadingProfile ? (
				<Preloader />
			) : showProfile ? (
				<div className='profile'>
					<div
						style={{ position: 'absolute', right: '0', top: '20px' }}
						onClick={() => showConnectModal()}
					>
						<MyButton
							variant='smallIcon'
							style={{ right: '20px' }}
							className={` ${isWalletButtonRef ? 'active' : ''}`}
						>
							<Icons.Wallet />
						</MyButton>
						<div
							style={{
								height: '48px',
								width: '44px',
								position: 'absolute',
								right: '20px',
							}}
						>
							<TonConnectButton
								style={{
									position: 'absolute',
									height: '100%',
									width: '100%',
									zIndex: '100',
								}}
							/>
						</div>
					</div>
					<MyBalance>
						<span>Your shit balance:</span>
						<div>
							{ballance}
							<Icons.Shit />
						</div>
					</MyBalance>
					<div className='rive_container_profile'>
						<img src={potatoImage} alt='potato' />
					</div>
					{username ? <div className='profile_user_name'>@{username}</div> : null}
					<div className='referals_count'>
						{referrals} referral{referrals > 1 ? 's' : ''}
					</div>
					<MyButton
						style={{
							fontSize: '22px',
							maxWidth: '300px',
							width: '100%',
							padding: '0',
							height: '42px',
							marginTop: '16px',
						}}
						onClick={handleProfileToggle} // Updated to use new handler
					>
						Referral program
						<Icons.Rarrow />
					</MyButton>
				</div>
			) : (
				<div className='frens'>
					<div className='frens__main'>
						<div className='frens__container container'>
							<MyButton
								variant='smallIcon'
								style={{ top: '-34px' }}
								onClick={handleProfileToggle}
							>
								<Icons.Larrow />
							</MyButton>
							<div className='frens__body'>
								{referrals >= 0 ? (
									
									<h1 className='frens__title'>
										<Icons.profileFrens /> <br />
										{referrals} {referrals === 1 ? 'fren' : 'frens'}
									</h1>
								) : (
									<h1 className='frens__title'>
										<br /> frens
									</h1>
								)}
								<p className='frens__text'>Invite frens and earn $hit</p>
								<MyButton
									style={{
										fontSize: '22px',
										maxWidth: '300px',
										width: '100%',
										padding: '0',
										height: '42px',
									}}
									onClick={inviteTG}
									disabled={!generatedUrl}
								>
									<Icons.Invite />
									invite frens
								</MyButton>
							</div>
						</div>
					</div>
					<div className='frens__options'>
						<div className='frens__container container'>
							<div className='frens__body'>
								<p className='frens__text'>Invite or copy/paste your link</p>
								<MyButton
									onClick={copyToClipboard}
									style={{
										maxWidth: '300px',
										width: '100%',
										padding: '0',
										height: '42px',
									}}
									data-link='referral link'
									data-notification='link-copied'
								>
									<Icons.Copy />
									Copy referral link
								</MyButton>
							</div>
							<div className='frens__more'>
								<p className='frens__text'>More info</p>
								<MyAnnounce style={{
									  marginTop: '16px'
								}}>
									<div>
										<h2>invite a friend</h2>
										<p>with telegram premium</p>
									</div>
									<button disabled>
										+3,0 <Icons.Shit />
									</button>
								</MyAnnounce>
							</div>
						</div>
					</div>
				</div>
			)}
			<NavigationWidget />
		</>
	);
};

export default ProfileComponent;
