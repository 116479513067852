import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import NavigationWidget from '../NavigationWidget';
import {
	useGetLeaderboardMutation,
	useGetTopVotersMutation,
	useGetTopReferralsMutation,
} from '../../../../services/phpService';
import Icons from '../../../Common/IconsComponent.jsx';
import { MyButton, MyTitle } from '../../../Common/styles';
import { usePopup } from '../../../Popups/PopupContext';

const RatingsComponent = () => {
	const [getLeaderboard] = useGetLeaderboardMutation();
	const [getTopVoters] = useGetTopVotersMutation();
	const [getTopReferrals] = useGetTopReferralsMutation();
	const [leaderboardData, setLeaderboardData] = useState([]);
	const [topVotersData, setTopVotersData] = useState([]);
	const [topReferralsData, setTopReferralsData] = useState([]);
	const [activeTab, setActiveTab] = useState('leaderboard');
	const [isLoading, setIsLoading] = useState(true);
	const { user } = usePopup();

	const navigate = useNavigate();

	// Clear localStorage on page refresh
	useEffect(() => {
		window.localStorage.removeItem('leaderboardData');
		window.localStorage.removeItem('topVotersData');
		window.localStorage.removeItem('topReferralsData');
	}, []);

	useEffect(() => {
		if (!user) {
			navigate('/?r=ratings');
		} else {
			setIsLoading(true);
		}
	}, [user, navigate]);

	const fetchData = async (tab) => {
		try {
			setIsLoading(true);
			const loadTimeStart = Date.now();
			let res;

			// Check if data for the tab is already in localStorage
			const storedData = localStorage.getItem(`${tab}Data`);
			if (storedData) {
				setTabData(tab, JSON.parse(storedData));
				setIsLoading(false);
				return;
			}

			// Fetch data based on the active tab
			switch (tab) {
				case 'leaderboard':
					res = await getLeaderboard(user.id_telegram);
					if (res?.data) {
						setLeaderboardData(res.data);
						localStorage.setItem('leaderboardData', JSON.stringify(res.data));
					}
					break;
				case 'topVoters':
					res = await getTopVoters(user.id_telegram);
					if (res?.data) {
						setTopVotersData(res.data);
						localStorage.setItem('topVotersData', JSON.stringify(res.data));
					}
					break;
				case 'topReferrals':
					res = await getTopReferrals(user.id_telegram);
					if (res?.data) {
						setTopReferralsData(res.data);
						localStorage.setItem('topReferralsData', JSON.stringify(res.data));
					}
					break;
				default:
					console.warn('No fetch method for this path');
					break;
			}

			const loadTimeEnd = Date.now();
			const elapsedTime = loadTimeEnd - loadTimeStart;
			const remainingTime = 750 - elapsedTime;

			if (remainingTime > 0) {
				setTimeout(() => setIsLoading(false), remainingTime);
			} else {
				setIsLoading(false);
			}
		} catch (error) {
			console.error(`Error fetching data for ${tab}:`, error);
		}
	};

	const setTabData = (tab, data) => {
		switch (tab) {
			case 'leaderboard':
				setLeaderboardData(data);
				break;
			case 'topVoters':
				setTopVotersData(data);
				break;
			case 'topReferrals':
				setTopReferralsData(data);
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		fetchData(activeTab);
	}, [activeTab]);

	const getBalanceField = (player) => {
		switch (activeTab) {
			case 'leaderboard':
				return player.wallet_balance;
			case 'topVoters':
				return player.total_votes;
			case 'topReferrals':
				return player.referrals_count;
			default:
				return '';
		}
	};

	const [containerWidth, setContainerWidth] = useState(0);
	const containerRef = useRef(null);

	useEffect(() => {
		const updateContainerWidth = () => {
			if (containerRef.current) {
				setContainerWidth(containerRef.current.offsetWidth);
			}
		};
		updateContainerWidth();
		window.addEventListener('resize', updateContainerWidth);
		return () => {
			window.removeEventListener('resize', updateContainerWidth);
		};
	}, []);

	const numberOfRects = 12;
	const rects = [];

	for (let i = 0; i < numberOfRects; i++) {
		rects.push(
			<rect key={i} x='0' y={i * 58} rx='16' ry='16' width={containerWidth} height='52' />
		);
	}

	const renderTabContent = (data) => {
		return (
			<ul className='ratings__list'>
				{data
					.filter((player) => {
						const balance = getBalanceField(player);
						return balance != null && balance !== false && balance !== 0;
					})
					.map((player, index) => {
						const isCurrentUser = player.username === user.username;
						const isTopThree = index < 2;
						return (
							<li
								className={`ratings__listItem ${
									isCurrentUser && !isTopThree ? 'highlight' : ''
								}`}
								key={index}
							>
								<div className='ratings__itemData'>
									<div className='ratings__listId'>
										<Icons.PlayerId />
										<span>{player.position}</span>
									</div>
									<div className='ratings__listName'>
										<span>
											{player.username?.length > 10
												? `${player.username.substring(0, 10)}...`
												: player.username ?? 'anonymous'}
										</span>
									</div>
									<div className='ratings__listBalance'>
										<span>{getBalanceField(player)}</span>
										{activeTab !== 'topReferrals' && <Icons.Shit />}
									</div>
								</div>
							</li>
						);
					})}
			</ul>
		);
	};

	return (
		<>
			<div className='ratings'>
				<div className='ratings__container container'>
					<div className='ratings__body' ref={containerRef}>
						<MyTitle>Leaderboard</MyTitle>
						<div className='ratings__links'>
							<MyButton
								variant='smallTab'
								className={`${activeTab === 'leaderboard' ? 'active' : ''}`}
								onClick={() => setActiveTab('leaderboard')}
							>
								top point
							</MyButton>

							<MyButton
								variant='smallTab'
								className={`${activeTab === 'topReferrals' ? 'active' : ''}`}
								onClick={() => setActiveTab('topReferrals')}
							>
								top referrals
							</MyButton>

							<MyButton
								variant='smallTab'
								className={`${activeTab === 'topVoters' ? 'active' : ''}`}
								onClick={() => setActiveTab('topVoters')}
							>
								top activity
							</MyButton>
						</div>

						{isLoading ? (
							<ContentLoader
								speed={2}
								width={'100%'}
								height={'100%'}
								viewBox='0 0 {containerWidth} 700'
								backgroundColor='#f3f3f3'
								foregroundColor='#ecebeb'
							>
								{rects}
							</ContentLoader>
						) : (
							<>
								{activeTab === 'leaderboard' && renderTabContent(leaderboardData)}
								{activeTab === 'topVoters' && renderTabContent(topVotersData)}
								{activeTab === 'topReferrals' && renderTabContent(topReferralsData)}
							</>
						)}
					</div>
				</div>
			</div>
			<NavigationWidget />
		</>
	);
};

export default RatingsComponent;
