import React, { useState, useEffect, useRef } from 'react';
import Icons from '../../../../../Common/IconsComponent.jsx';
import { usePopup } from '../../../../../Popups/PopupContext.js';
import { usePassTaskMutation } from '../../../../../../services/phpService.js';
import { animateDoneTask } from '../../../../../../helpers/tasksAnimationHelper.js';

const EventTasksItem = ({ task, eventId }) => {
	const { user, setUser, genHash, addNotification } = usePopup();

	const [isClicked, setIsClicked] = useState(false);
	const [isCheckStage, setIsCheckStage] = useState(false);
	const [remainingTime, setRemainingTime] = useState(10);
	const [timerStarted, setTimerStarted] = useState(false);
	const [timerFinished, setTimerFinished] = useState(false);
	const [taskStatusDone, setTaskStatusDone] = useState(false);
	const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [checkTasks] = usePassTaskMutation();
	const taskElement = useRef(null);

	useEffect(() => {
		if (
			user?.projects_tasks?.some((projTask) => projTask.projects_task_id === task.taskId)
		) {
			setTaskStatusDone(true);
		}
	}, [user, task.taskId]);

	useEffect(() => {
		let interval;
		if (timerStarted && !timerFinished) {
			interval = setInterval(() => {
				setRemainingTime((prev) => {
					if (prev > 1) {
						return prev - 1;
					} else {
						clearInterval(interval);
						setTimerFinished(true);
						return 0;
					}
				});
			}, 1000);
		}
		return () => clearInterval(interval);
	}, [timerStarted, timerFinished]);

	const openLink = (link) => {
		if (!taskStatusDone) {
			setIsClicked(true);
			setIsCheckStage(true);
			setShowConfirmationMessage(true);
		}
		window.open(link, '_blank');
	};

	const startTimer = (event) => {
		event.stopPropagation();
		setTimerStarted(true);
	};

	const checkTask = async (event) => {
		event.stopPropagation();
		if (isButtonDisabled || !timerFinished) {
			if (!timerFinished) {
				addNotification('error', 'Task is not completed. Please wait for the timer.');
			}
			return;
		}

		setIsButtonDisabled(true);

		try {
			const res = await checkTasks({
				token: await genHash(),
				id_telegram: user?.id_telegram,
				event_id: eventId,
				projects_task_id: task?.taskId,
			}).unwrap();

			const taskExistsInUpdatedList = res.projects_tasks?.some(
				(projTask) => projTask.projects_task_id === task.taskId
			);

			if (taskExistsInUpdatedList) {
				setIsClicked(false);
				setTaskStatusDone(true);
				setShowConfirmationMessage(false);
				animateDoneTask(taskElement);

				const updatedUser = {
					...user,
					projects_tasks: res.projects_tasks,
					wallet_balance: res.wallet_balance,
				};
				setUser(updatedUser);
				addNotification('success', 'Great job! The task is done!');
			} else {
				addNotification('error', 'Task check failed. Please try again.');
			}
		} catch (e) {
			console.log(e);
			if (e?.data?.message) {
				addNotification('error', 'Oops... Please try again later.');
			}
		}
	};

	return (
		<a
			href='#'
			className='tasks__link'
			onClick={() => openLink(task?.link)}
			ref={taskElement}
		>
			<div className='tasks__name'>
				{task?.name}
				{!taskStatusDone && showConfirmationMessage && (
					<div style={{ fontFamily: 'Kumbh', color: '#fff500', marginTop: '5px' }}>
						Are you certain you complete this task?
					</div>
				)}
			</div>

			{taskStatusDone ? (
				<div className='tasks__value tasks__value_done'>done</div>
			) : isClicked ? (
				<div className='tasks__value tasks__value_check'>
					{isCheckStage ? (
						timerStarted ? (
							timerFinished ? (
								<button onClick={checkTask} disabled={isButtonDisabled}>
									Claim
								</button>
							) : (
								<span onClick={(e) => e.stopPropagation()}>
									{remainingTime}&nbsp;<span style={{ fontSize: '14px' }}>s</span>
								</span>
							)
						) : (
							<button onClick={startTimer}>Check</button>
						)
					) : null}
				</div>
			) : (
				<div onClick={() => openLink(task?.link)} className='tasks__value'>
					+{task?.bonus} <Icons.ShitIcon />
				</div>
			)}
		</a>
	);
};

export default EventTasksItem;
