	import React, { useState, useEffect } from 'react';
	import { usePopup } from '../../../../../../Popups/PopupContext.js';
	import { usePassEventMutation } from '../../../../../../../services/phpService';
	import Icons from '../../../../../../Common/IconsComponent.jsx';
	import { MyButton } from '../../../../../../Common/styles';
	import '../StartEvent.scss';

	const StartEventScreenModal = ({ id_telegram, gamingId, payValueShit, payValueStars, walletBalance, eventStatus, eventId, navigate }) => {
		const { hidePopup, user } = usePopup();
		const [passEvent] = usePassEventMutation();
		const [currentStep, setCurrentStep] = useState('select');
		const [paymentType, setPaymentType] = useState(null);
		const [errorMessage, setErrorMessage] = useState(null);

		// useEffect(() => {
		//     setUserDataPollingDisabled(false);

		//     return () => {
		//         setUserDataPollingDisabled(true);
		//     };
		// }, [setUserDataPollingDisabled]);

		const handleSelectPayment = (type) => {
			setPaymentType(type);
			setCurrentStep('confirm'); 
		};
		
		const handlePayment = async () => {
			setErrorMessage(null); 
			try {
				const response = await passEvent({
					id_telegram: id_telegram,
					event_gaming_id: gamingId,
					payment_type: paymentType,
				});

				if (paymentType === 2) {
					if (response && response.data.data.invoice_response.result) {
						setErrorMessage(null);  
						const resultUrl = response.data.data.invoice_response.result;
						window.open(resultUrl, '_blank');
					} else {
						setErrorMessage('Failed to join event with stars.');
					}
				}
		
				else if (paymentType === 1) {
					if (response) {
						setErrorMessage(null);
					} else {
						setErrorMessage('Failed to join event with points.');
					}
				}
			} catch (error) {
				setErrorMessage('Failed to join event.');
				console.error('Error joining event:', error);
			}
		};

		const closeModal = () => {
			hidePopup();
		};

		useEffect(() => {
			if (user?.events_gaming && user.events_gaming.length > 0) {
				setCurrentStep('congratulations');
			}
		}, [user?.events_gaming]);
		
		const handleContinue = () => {
			if (eventStatus === 0) {
				navigate(`/events/${eventId}`);
			} else if (eventStatus === 1) {
				navigate(`/events/${eventId}/game`);
			}
			hidePopup();
		};

		return (
			<div className="startEventModal">
				{currentStep === 'select' && (
					<>
						<div className='modal__close' onClick={closeModal}>
							<Icons.Close />
						</div>
						<div className="startEventModal__icon">
							<Icons.Rocket />
						</div>
						<div className="startEventModal__text">
							<span>SELECT PAYMENT METHOD</span>
						</div>
						<div className="startEventModal__btn-group" style={{width: '265px'}}>
							<MyButton onClick={() => handleSelectPayment(1)}>pay points</MyButton>
							<MyButton variant='black' onClick={() => handleSelectPayment(2)}>pay tg stars</MyButton>
						</div>
					</>
				)}

				{currentStep === 'confirm' && (
					<>
						<div className='modal__close' onClick={closeModal}>
							<Icons.Close />
						</div>
						<div className="startEventModal__text">
						<span>{paymentType === 1 ? 'POINTS to be paid' : 'STARS to be paid'}</span>
						</div>
						<div className="startEventModal__price">
							<span>+ {paymentType === 1 ? payValueShit : payValueStars}</span>
							{paymentType === 1 ? <Icons.Shit /> : <Icons.Rocket />}
						</div>
						<div className="startEventModal__balance">
							<span>Your total $hit balance:</span>&nbsp;{walletBalance}
						</div>
						{errorMessage && <div className="error-message">{errorMessage}</div>}
						<div className="startEventModal__btn-group" style={{flexDirection: 'row'}}>
							<MyButton onClick={() => setCurrentStep('select')}>Cancel</MyButton>
							<MyButton variant='black' onClick={handlePayment}>Pay</MyButton>
						</div>
					</>
				)}

				{currentStep === 'congratulations' && (
					<>
						<div className="startEventModal__icon">
							<Icons.Star />
						</div>
						<div className="startEventModal__text">
							<span>Congratulations!</span>
							<p>The payment was successful</p>
							<MyButton style={{marginTop: '30px', width: '100%'}} 
							onClick={handleContinue}
							>continue</MyButton>
						</div>
					</>
				)}
			</div>
		);
	};

	export default StartEventScreenModal;
