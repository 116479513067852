import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Icons from '../../../../../Common/IconsComponent.jsx';
import Trump from '../../../../../../assets/img/Trump.webp';
import Kamala from '../../../../../../assets/img/Harris.webp';
import { MyButton } from '../../../../../Common/styles';
import { usePopup } from '../../../../../Popups/PopupContext.js';
import StartEventModal from './StartEventScreenModal/StartEventScreenModal.jsx';
import './StartEvent.scss';

const StartEvent = () => {
	const location = useLocation();
	const { gamingId, payValueShit, payValueStars, eventId, eventStatus } = location.state;
	const navigate = useNavigate();
	const { showPopup, user,  } = usePopup();

	const navBack = () => {
		navigate(`/events/${eventId}`); 
	};

	const selectedImage = gamingId === 1 ? Trump : Kamala;

	const startEvent = () => {
		if (user?.id_telegram) {
			showPopup(<StartEventModal 
				id_telegram={user?.id_telegram} 
				gamingId={gamingId}
				payValueShit={payValueShit} 
				payValueStars={payValueStars} 
				walletBalance={user?.wallet_balance} 
				eventStatus={eventStatus}
				eventId={eventId}
				navigate={navigate}
				/>);
		} else {
			console.error('id_telegram is missing!');
		}
	};

	return (
		<div className='startEvent'>
			<MyButton
				onClick={navBack}
				variant='smallIcon'
				style={{ top: '20px', left: '20px' }}
			>
				<Icons.Larrow />
			</MyButton>
			<div className='container startEvent__container'>
				<div className='startEvent__img'>
					<img src={selectedImage} alt={gamingId === 1 ? 'Trump' : 'Kamala'} />
				</div>
				<div className='startEvent__icon'>
					<Icons.Money />
				</div>
				<div className='startEvent__title'>
					<span>
						Pay <b> 1 Ton</b> or <b>100 Shit</b> points and start the game
					</span>
				</div>
				<div className='startEvent__descr'>
					<span>
						Each team must have a pool of players to start the general game. up to{' '}
						<b>10,000 per team</b>
					</span>
				</div>
				<div className='startEvent__descr'>
					<span>
						The challenge has a <b>2 weeks</b> limit
					</span>
				</div>
				<div className='startEvent__item-btn'>
					<MyButton onClick={startEvent}>pay to start</MyButton>
				</div>
			</div>
			<div className='startEvent__item'>
				<div className='container'>
					<div className='startEvent__item-box'>
						<div className='startEvent__item-header'>
							<span>Tap to Play!</span>
						</div>
						<div className='startEvent__item-descr'>
							<span>
								Hit the button, tap your character, complete tasks, and accumulate points.
							</span>
						</div>
					</div>
					<div className='startEvent__item-box'>
						<div className='startEvent__item-header'>
							<span>Help your team to win</span>
						</div>
						<div className='startEvent__item-descr'>
							<span>
								Actively tap and use boost, invite your friends to win the competition
							</span>
						</div>
					</div>
					<div className='startEvent__item-box'>
						<div className='startEvent__item-header'>
							<span>Tasks</span>
						</div>
						<div className='startEvent__item-descr'>
							<span>
								Earn points from start-up missions and extra tasks. Get rewarded for your
								efforts!
							</span>
						</div>
					</div>
					<div className='startEvent__item-box'>
						<div className='startEvent__item-header'>
							<span>Rankings</span>
						</div>
						<div className='startEvent__item-descr'>
							<span>
								Compete and earn a share of points if your favored projects win. Dominate
								the charts!
							</span>
						</div>
					</div>
					<div className='startEvent__item-box'>
						<div className='startEvent__item-header'>
							<span>Prizes</span>
						</div>
						<div className='startEvent__item-descr'>
							<span>
								Finish in top 50 to collect Ton prize from our bank. Collect your rewards!
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StartEvent;
