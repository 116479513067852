import { useEffect, useState, useRef } from 'react';
import React from 'react';
import NavigationWidget from '../NavigationWidget';
import ContentLoader from 'react-content-loader';
import { useGetActivityMutation } from '../../../../services/phpService';
import Icons from '../../../Common/IconsComponent.jsx';
import { usePopup } from '../../../Popups/PopupContext';
import { MyButton, MyTitle } from '../../../Common/styles';
import { useNavigate, useLocation } from 'react-router-dom';

export default function ProjectsScreen() {
    const [getActivity] = useGetActivityMutation();
    const [allProjects, setAllProjects] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]); 
    const [categories, setCategories] = useState([]);
    const [activeCategory, setActiveCategory] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { user } = usePopup();
    const baseURL = process.env.REACT_APP_SECRET_IMG;
    const [containerWidth, setContainerWidth] = useState(0);
    const containerRef = useRef(null);
    const location = useLocation();
    const { activityId } = location.state || {};  

    useEffect(() => {
        if (!user) navigate('/?r=projects');
    }, [user, navigate]);

    useEffect(() => {
        const fetchProjects = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`https://admin.theshit.io/api/activity/${activityId}`);
                const data = await response.json();
                if (Array.isArray(data)) { 
                    setAllProjects(data); 
                    setFilteredProjects(data); 
                    localStorage.setItem('projectsData', JSON.stringify(data));
                } else {
                    setAllProjects([]);
                    setFilteredProjects([]);
                }
            } catch (error) {
                console.error('Error fetching projects data:', error);
                setAllProjects([]); 
                setFilteredProjects([]); 
            } finally {
                setIsLoading(false);
            }
        };
        fetchProjects();
    }, [activityId]); 

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const res = await getActivity();
                if (res?.data) {
                    setCategories(res.data); 
                    if (activityId) {
                        filterProjectsByCategory(res.data, activityId); 
                    }
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, [getActivity, activityId]);

    // Фильтрация проектов по категории
    const filterProjectsByCategory = (categories, selectedCategory) => {
        if (allProjects.length === 0 || !categories) return;

        const categoryObj = categories.find(cat => cat.name === selectedCategory);
        if (!categoryObj) return;

        const filtered = allProjects.filter(project => project.category_id === categoryObj.id);
        setFilteredProjects(filtered);
        setActiveCategory(selectedCategory);
    };

    // const handleCategoryChange = (newCategory) => {
    //     filterProjectsByCategory(categories, newCategory);
    // };

    const handleProjectClick = (project) => {
        navigate(`/projects/${project.id}`);
    };

    useEffect(() => {
        const updateContainerWidth = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
            }
        };
        updateContainerWidth();
        window.addEventListener('resize', updateContainerWidth);
        return () => {
            window.removeEventListener('resize', updateContainerWidth);
        };
    }, []);

    const numberOfRects = 12;
    const rects = Array.from({ length: numberOfRects }, (_, i) => (
        <rect key={i} x='0' y={i * 51} rx='16' ry='16' width={containerWidth} height='45' />
    ));

    const renderTabContent = () => {
        return (
            <ul className='ratings__list'>
                {filteredProjects.map((project, index) => (
                    <li
                        className={`ratings__item ${project.has_game === 1 ? 'ratings__highlighted' : ''}`}
                        key={index}
                        onClick={() => handleProjectClick(project)}
                    >
                        <div className='ratings__image'>
                            {project.has_game === 1 && (
                                <div style={{ position: 'absolute', display: 'flex', alignItems: 'center', zIndex: 10 }}>
                                    <Icons.ProjEvent />
                                </div>
                            )}
                            <img src={`${baseURL}${project.image}`} alt={project.name} />
                        </div>
                        <div className='ratings__name'>{project.name}</div>
                        <div className='ratings__value'>
                            {project.vote_total}
                            <Icons.Shit />
                        </div>
                    </li>
                ))}
            </ul>
        );
    };

    const navBack = () => {
		navigate(`/activity/`);
	};


    return (
        <>
            <div className='ratings'>
                <div className='ratings__container container'>
                    <div className='ratings__body' style={{height:'100%'}} ref={containerRef}>
                        <MyButton
								variant='smallIcon'
								onClick={() => navBack()}
							>
								<Icons.Larrow />
							</MyButton>
                        <MyTitle style={{margin:'14px 0 12px 68px'}}>best mem coins</MyTitle>
                        {/* <div className='ratings__links'>
                            {categories.map((cat) => (
                                <MyButton
                                    key={cat.id}
                                    variant='smallTab'
                                    className={`${activeCategory === cat.name ? 'active' : ''}`}
                                    onClick={() => handleCategoryChange(cat.name)}
                                >
                                    {cat.name}
                                </MyButton>
                            ))}
                        </div> */}
                        {isLoading ? (
                            <ContentLoader
                                speed={1}
                                width='100%'
                                height='100%'
                                viewBox={`0 0 ${containerWidth} 600`}
                                backgroundColor='#f3f3f3'
                                foregroundColor='#ecebeb'
                            >
                                {rects}
                            </ContentLoader>
                        ) : (
                            renderTabContent()
                        )}
                    </div>
                </div>
            </div>
            <NavigationWidget />
        </>
    );
}
