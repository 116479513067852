import React, { useState, useEffect, useRef, useCallback } from 'react';
import Icons from '../../../../../Common/IconsComponent';
import { AnimatePresence, motion } from 'framer-motion';
import { usePopup } from '../../../../../Popups/PopupContext';
import {
	useUpdateEventBalanceMutation,
	useGetEventsMutation,
} from '../../../../../../services/phpService';
import { useParams, useNavigate } from 'react-router-dom';
import harrisHero from '../../../../../../assets/animations/harris.riv';
import trumpHero from '../../../../../../assets/animations/trump.riv';
import debounce from 'lodash.debounce';
import useBoost from '../../../../../../helpers/clickerBoostHelper';
import moment from 'moment-timezone';
import boostIcon from '../../../../../../assets/img/shitBoost.webp';
import EventTasks from './EventTasks';
import ClickerLeaders from '../../../Projects/Project/ClickerLeaders';
import Preloader from '../../../Preloader/Preloader';
import { MyButton, MyBalance } from '../../../../../Common/styles';
import { useRive, useStateMachineInput } from '@rive-app/react-canvas';
import eventFlag from '../../../../../../assets/img/flagImg.webp';
import './Event.scss';

export default function Event() {
	const { genHash, addNotification, user, showPopup } = usePopup();
	const [updateEventBalance] = useUpdateEventBalanceMutation();
	const [getEvents] = useGetEventsMutation();

	const [currCoins, setCurrCoins] = useState(0);
	const [gamePaused, setGamePaused] = useState(false);
	const [timeRemaining, setTimeRemaining] = useState(null);
	const [isAnimationActive, setIsAnimationActive] = useState(false);
	const [animations, setAnimations] = useState([]);
	const accumulatedCoinsRef = useRef(0);
	const [totalPoints, setTotalPoints] = useState(null);
	const [activeTasksCount, setActiveTasksCount] = useState(0);
	const [canPlayAt, setCanPlayAt] = useState(10);
	const [isLoading, setIsLoading] = useState(true);

	const [eventsData, setEventsData] = useState([]);
	const [matchingEvent, setMatchingEvent] = useState(null); // Store matchingEvent in state
	const { eventId: eventIdParam } = useParams();
	const navigate = useNavigate();

	const [energyVal, setEnergyVal] = useState(user?.tap_value ?? 1);
	const [clickNewCoins, setClickNewCoins] = useState(user?.tap_value ?? 1);
	const [unsubmittedCoins, setUnsubmittedCoins] = useState(0);

	const { visible, position, activateBoost } = useBoost(gamePaused);
	const eventId = Number(eventIdParam);
	const secretURL = process.env.REACT_APP_SECRET_URL;

	useEffect(() => {
		if (user) {
			const foundEvent = user.events_gaming.find((event) => event.event_id === eventId);
			setMatchingEvent(foundEvent);

			if (foundEvent) {
				setTotalPoints(foundEvent.votes);
				setCurrCoins(foundEvent.energy);
			} else {
				navigate('/');
			}
			setIsLoading(false);
		}
	}, [user]);

	useEffect(() => {
		const fetchEvents = async () => {
			try {
				const res = await getEvents().unwrap();
				setEventsData(res);
			} catch (error) {
				console.error('Something went wrong:', error);
			}
		};
		fetchEvents();
	}, [getEvents]);

	useEffect(() => {
		if (user && eventsData.length > 0) {
			// Find the tasks for the current event
			const matchingTasks = eventsData[0]?.tasks?.filter(
				(task) => task.events_gaming_id === eventId || task.events_gaming_id === 0
			);
			setActiveTasksCount(matchingTasks.length);
		}
	}, [eventsData, eventId, user]);

	const navBack = useCallback(() => navigate(`/events/${eventId}`), [navigate, eventId]);

	const animationSrc = eventId === 1 ? trumpHero : eventId === 2 ? harrisHero : null;

	const { rive, RiveComponent: EventHeroComponent } = useRive(
		{
			src: animationSrc,
			stateMachines: 'State Machine 1',
			autoplay: true,
		},
		{ enabled: Boolean(animationSrc) }
	);
	const tapInput = useStateMachineInput(rive, 'State Machine 1', 'tap');

	const riveComponentStyle = {
		scale: '1',
		zIndex: 10,
	};

	useEffect(() => {
		if (matchingEvent) {
			setCurrCoins(matchingEvent.energy);
		}
	}, [matchingEvent]);

	useEffect(() => {
		const currentTimeStamp = moment.tz('UTC').unix();
		const remainingTime = canPlayAt - currentTimeStamp;

		if (remainingTime <= 0) {
			setGamePaused(false);
			setTimeRemaining(null);
			return;
		}

		const updateGameStatus = () => {
			const remainingTime = canPlayAt - moment.tz('UTC').unix();
			if (remainingTime <= 0) {
				setGamePaused(false);
				setTimeRemaining(null);
				clearInterval(timer);
			} else {
				setGamePaused(true);
				setTimeRemaining(remainingTime);
			}
		};

		const timer = setInterval(updateGameStatus, 1000);
		return () => clearInterval(timer);
	}, [canPlayAt]);

	const boostClickedHandler = () => {
		activateBoost(energyVal, clickNewCoins, setEnergyVal, setClickNewCoins, user);
	};

	const submitData = useCallback(
		async (coins) => {
			const cappedCoins = Math.min(coins, 1000);
			try {
				const token = await genHash();
				await updateEventBalance({
					token,
					id_telegram: user.id_telegram,
					event_gaming_id: eventId,
					score: cappedCoins,
				}).unwrap();
			} catch (e) {
				setUnsubmittedCoins((prev) => prev + cappedCoins);
			}
		},
		[genHash, eventId, updateEventBalance, user]
	);

	const updateBalance = useCallback(() => {
		const coinsToSubmit = accumulatedCoinsRef.current + unsubmittedCoins;
		if (coinsToSubmit > 0) {
			submitData(coinsToSubmit);
			accumulatedCoinsRef.current = 0;
			setUnsubmittedCoins(0);
		}
	}, [submitData, unsubmittedCoins]);

	const debouncedUpdateBalanceRef = useRef(debounce(updateBalance, 1200));

	const pauseGame = useCallback(async () => {
		const futureTimestamp = Math.floor(Date.now() / 1000) + 10;
		try {
			await fetch(secretURL + '/api/set-project-activity', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					token: await genHash(),
					id_telegram: user.id_telegram,
					event_gaming_id: eventId,
					timestamp: futureTimestamp,
				}),
			});
			addNotification('success', 'Good job! You just voted for free');
			setCurrCoins(0);
		} catch (error) {
			addNotification('error', 'Your vote was not counted. Please try again.');
		}
	}, [genHash, eventId, secretURL, user]);

	useEffect(() => {
		let pauseTimeoutId;
		if (currCoins >= (user?.max_energy ?? 1000)) {
			setGamePaused(true);
			pauseTimeoutId = setTimeout(() => {
				pauseGame();
			}, 1500);
		}
		return () => clearTimeout(pauseTimeoutId);
	}, [currCoins]);

	const handleShowAnimation = useCallback((event) => {
		if (!event) return;
		const touch = event.touches ? event.touches[0] : event;
		const x = touch.pageX;
		const y = touch.pageY;
		const id = Date.now();
		setAnimations((prev) => [...prev, { id, x, y }]);
		setIsAnimationActive(true);
	}, []);

	const handleTouchStart = (event) => handleShowAnimation(event);

	const handleTouchEnd = () => {
		const clickValue = clickNewCoins;
		setCurrCoins((prev) => Math.min(prev + clickValue, 1000));
		accumulatedCoinsRef.current += clickValue;

		if (!gamePaused && tapInput) {
			tapInput.fire();
		}

		debouncedUpdateBalanceRef.current();
	};

	const calculateStrokeDasharray = (currCoins) => {
		const circleCircumference = 2 * Math.PI * 45;
		const percentage = (currCoins / 1000) * circleCircumference;
		return `${percentage} ${circleCircumference}`;
	};

	const handleShowLeaders = () => showPopup(<ClickerLeaders eventId={eventId} />);
	const handleMakeClick = () => {
		const matchingTasks = eventsData[0]?.tasks?.filter(
			(task) => task.events_gaming_id === eventId || task.events_gaming_id === 0
		);
		showPopup(<EventTasks eventId={eventId} tasks={matchingTasks} />);
	};

	const removeAnimation = useCallback((id) => {
		setAnimations((prev) => prev.filter((anim) => anim.id !== id));
	}, []);

	return (
		<>
			<div className='eventWrapper'>
				{isLoading ? (
					<Preloader />
				) : (
					<>
						<MyButton
							variant='smallIcon'
							style={{ top: '20px', left: '20px' }}
							onClick={() => navBack()}
						>
							<Icons.Larrow />
						</MyButton>
						<MyButton
							variant='smallIcon'
							style={{ top: '20px', right: '20px' }}
							onClick={handleShowLeaders}
						>
							<Icons.CupWhite />
						</MyButton>

						<MyBalance>
							<div style={{ fontSize: '18px' }}>Your votes:</div>
							<div style={{ margin: '0', fontSize: '38px' }}>{totalPoints ?? 0}</div>
						</MyBalance>

						{!gamePaused && visible ? (
							<motion.div
								initial={{
									y: 7,
									rotate: 0,
									opacity: 1,
								}}
								animate={{
									y: [5, 15, 20],
									x: [-15, 15, 5],
								}}
								transition={{
									duration: 4,
									repeat: Infinity,
									repeatType: 'mirror',
									ease: 'easeInOut',
								}}
								style={{
									position: 'absolute',
									top: '50%',
									left: 0,
									zIndex: 150,
								}}
							>
								<motion.div
									animate={{
										opacity: [0, 1],
									}}
									transition={{
										duration: 4,
										repeat: Infinity,
										repeatType: 'mirror',
										ease: 'easeInOut',
									}}
								>
									<div
										className='boost-element'
										style={{
											position: 'absolute',
											overflow: 'hidden',
											left: `${position.x}px`,
											top: `${position.y}px`,
											cursor: 'pointer',
											width: '100px',
											height: '100px',
											zIndex: 25,
										}}
										onClick={boostClickedHandler}
									>
										<motion.img
											src={boostIcon}
											alt='Boost'
											style={{
												width: '100%',
												height: '100%',
												userSelect: 'none',
												scale: '100%',
											}}
											initial={{ opacity: 0, rotate: 0 }}
											animate={{ opacity: 1, rotate: 0 }}
											transition={{
												duration: 4,
												repeat: Infinity,
												repeatType: 'mirror',
												ease: 'easeInOut',
											}}
										/>
									</div>
								</motion.div>
							</motion.div>
						) : null}

						<div
							className='event__clickArea'
							onTouchStart={!gamePaused ? handleTouchStart : undefined}
							onTouchEnd={!gamePaused ? handleTouchEnd : undefined}
						>
							<AnimatePresence>
								{isAnimationActive &&
									animations.map((anim) => (
										<motion.div
											key={anim.id}
											className='clickerAnimation'
											initial={{ opacity: 1, y: 0 }}
											animate={{
												opacity: [1, 0],
												x: [-20, -20],
												y: window.innerHeight <= 685 ? [-185, -280] : [-260, -350],
											}}
											exit={{ opacity: 0 }}
											transition={{ duration: 0.5 }}
											style={{
												color: '#000',
												fontSize: '52px',
												left: `${anim.x}px`,
												top: `${anim.y}px`,
												position: 'absolute',
												color: '#333333',
												zIndex: 10,
											}}
											onAnimationComplete={() => removeAnimation(anim.id)}
										>
											<div className='clicker__clickValue'>
												<img src={eventFlag} alt='Paper Plane' />
											</div>
										</motion.div>
									))}
							</AnimatePresence>

							<div className='event__clickerHero'>
								<EventHeroComponent style={riveComponentStyle} />
							</div>
							<div className='event__progressBox'>
								<div className='event__progressBar'>
									<svg
										viewBox='0 0 100 100'
										style={{
											position: 'absolute',
											width: '100%',
											height: '100%',
											borderRadius: '100%',
										}}
									>
										<defs>
											<filter id='boxShadow' x='-20%' y='-20%' width='140%' height='140%'>
												<feGaussianBlur in='SourceAlpha' stdDeviation='3' />
												<feOffset dx='0' dy='0' result='offsetblur' />
												<feComponentTransfer>
													<feFuncA type='linear' slope='0.5' />
												</feComponentTransfer>
												<feMerge>
													<feMergeNode />
													<feMergeNode in='SourceGraphic' />
												</feMerge>
											</filter>
										</defs>

										<circle
											cx='50'
											cy='50'
											r='45'
											fill='none'
											stroke='#EEE'
											strokeWidth='2.5'
										></circle>
										{currCoins > 0 && (
											<circle
												cx='50'
												cy='50'
												r='45'
												fill='none'
												strokeWidth='2.5'
												strokeLinecap='round'
												strokeDasharray={calculateStrokeDasharray(currCoins)}
												style={{ transition: 'stroke-dasharray 0.35s' }}
												stroke='#000'
											></circle>
										)}
									</svg>
								</div>
							</div>
						</div>

						<div className='event__session'>
							<div className='event__seshCount'>
								<div className='event__sessionText'>
									<span>Session:</span>
								</div>
								<div className='event__sessionProgress'>{matchingEvent.sessions}/4</div>
							</div>
							<div className='blackLine'></div>
							<div className='event__seshProgr'>
								<div className='event__sessionText'>
									{gamePaused ? (
										<>
											{timeRemaining !== null ? (
												<>
													<Icons.clockIcon />
													<span style={{ marginLeft: '5px' }}>Next Tap session in:</span>
												</>
											) : (
												<span style={{ marginLeft: '5px' }}>Calculating...</span>
											)}
										</>
									) : (
										<span style={{ marginLeft: '5px' }}>Tap to vote:</span>
									)}
								</div>
								<div className='event__sessionProgress'>
									{gamePaused
										? timeRemaining || null
										: `${currCoins}/${user?.max_energy ?? 1000}`}
								</div>
							</div>
						</div>

						<div className='event__tip'>
							<p>Tap for win and get rewards!</p>
						</div>
						<MyButton
							variant='black'
							onClick={handleMakeClick} // Passing tasks with eventId
							className='mainBtn'
						>
							tasks <span style={{ color: 'black' }}>{activeTasksCount}</span>
						</MyButton>
					</>
				)}
			</div>
		</>
	);
}
